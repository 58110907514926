import {
  ArrowPathIcon,
  ArrowUturnLeftIcon,
  CameraIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Camera } from "react-camera-pro";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../lib/api";
import qs from "query-string";
import UploadModal from "../components/UploadMordal";

export default function CameraSearch() {
  const [text, setText] = useState("start");
  useEffect(() => setText("complete!"), []);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [upload, setUpload] = useState(false);

  const camera = useRef(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const findIsbn = (string) => {
    let str = string.replace(/-/g, "").toUpperCase().replace(/ /g, "");
    let str_position = str.indexOf("ISBN");
    let str_isbn = str.substr(str_position + 4, 13);
    // console.log(str_isbn, !isNaN(str_isbn), str_isbn.length);
    if (!isNaN(str_isbn) && str_isbn.length === 13) {
      navigate(state.location, { state: { isbn: str_isbn } });
    } else {
      window.alert("isbn 검색에 실패하였습니다.");
      setUpload(false);
    }
  };

  return (
    <>
      <UploadModal upload={upload} />
      <div className="fixed w-full h-full flex">
        <div className="landscape:w-[80%] w-full h-full flex justify-center items-center bg-black">
          {/* <div className="landscape:w-[80%] w-full h-full flex justify-center items-center bg-black"> */}
          <div className="w-3/4 p-2 text-white text-center xl:mt-20">
            {/* <div className="portrait:w-3/4 portrait:lg:w-1/3  m-8 landscape:w-1/2 flex box-content"> */}

            <Camera
              ref={camera}
              facingMode={`environment`}
              numberOfCamerasCallback={setNumberOfCameras}
              aspectRatio={1}
              errorMessages={{
                noCameraAccessible:
                  "사용 가능한 카메라가 없습니다. 카메라를 다시 연결해주시거나 다른 브라우저로 재시도 해주세요.",
                permissionDenied:
                  "카메라 사용이 거부되었습니다. 새로고침 후 카메라 사용을 허가해 주세요.",
                switchCamera:
                  "액세스할 수 있는 비디오 장치가 하나뿐이므로 카메라를 다른 카메라로 전환할 수 없습니다.",
                canvas: "Canvas가 지원되지 않습니다.",
              }}
            />
          </div>
        </div>
        <div className="bg-black fixed flex landscape:w-[20%] landscape:h-full portrait:w-full z-10 landscape:right-0 portrait:bottom-0 items-center justify-between p-8 portrait:flex-row landscape:flex-col-reverse">
          {/* {image ? (
          <img
            src={image}
            alt="preview"
            className={`w-16 aspect-square object-cover`}
          />
        ) : (
          <div className="w-16 h-16 border-gray-500 border text-gray-500 text-xs flex justify-center items-center">
            미리보기
          </div>
        )} */}
          <button
            onClick={() => {
              const photo = camera.current.takePhoto();
              setUpload(true);
              axios
                .post(
                  `/api/book/imageToText`,
                  qs.stringify({ image: photo.substr(23) }),
                  {
                    headers: {
                      Authorization: "bearer " + api.getToken(),
                    },
                  }
                )
                .then((res) => {
                  findIsbn(res.data.data);
                })
                .catch((err) => window.alert(err));
            }}
            className="bg-black text-white p-4 border-2 rounded-full hover:bg-gray-700 border-gray-600 mx-auto my-auto"
          >
            <CameraIcon className="h-8" />
          </button>
          {/* <div className="p-2">
          <button
            disabled={numberOfCameras <= 1}
            onClick={() => {
              camera.current.switchCamera();
            }}
            className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 disabled:bg-black"
          >
            <ArrowPathIcon className="h-8" />
          </button>
        </div> */}
        </div>
        <div
          onClick={() => navigate(-1)}
          className="text-white absolute top-4 left-4 bg-gray-800 p-4 rounded-full border-gray-900 hover:bg-gray-900/10"
        >
          <ArrowUturnLeftIcon className="h-6" />
        </div>
      </div>
    </>
  );
}
